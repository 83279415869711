@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400;1,500;1,600;1,700&family=Montserrat:wght@400;500;700&display=swap);
body, html {
  margin:0;
  overflow-x: hidden;
}

button {
  padding:1em;
  border:none;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

a {
  color:inherit;
  font-weight: bolder;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size:1.4em;
}

label {
  min-width: 2em;
}

em {
  font-family: "Lora";
  font-style: "italic"
}

.required:after { /* on label */
    content:" *";
    color: red;
  }

/* why not input:required:after ?
https://stackoverflow.com/questions/31753702/using-after-pseudoelement-with-required-pseudoclass
  input:required {
  background-color: yellow;
}
*/

.vertical-timeline-element-date
{
  opacity: 1 !important;
}

p {
  margin:0.5em 0;
  font-size: 16px;
  line-height: 1.4em;
}


@media screen and (min-width: 752px) {
  p {
    font-size: 18px;
    line-height: 1.3em;
  }
}

@media screen and (min-width: 1170px) {
  p {
    font-size: 20px;
    line-height: 1.2em;
  }
}

.burgericon
{
    cursor: pointer;
}

.bar1,
.bar2,
.bar3
{
    width: 25px;
    height: 3px;
    background-color: #333;
    margin-bottom: 5px;
    transition: 0.4s;
}

.bar3
{
    margin-bottom: 0;
}

.menudisplayed .bar1
{
    transform: translate(0px, 8.5px) rotate(-45deg) ;
    background-color:white;
}

.menudisplayed .bar2
{
    opacity: 0;
}

.menudisplayed .bar3
{
    transform: translate(0px, -7.5px) rotate(45deg) ;
    background-color:white;
}

nav {
  top: 0;
  left: 2vw; /* same than main content*/
  width:96vw; /* 100 - 2x margin for content */
  height: 6em;
  position:fixed;
  display: flex;
  align-items: center;
  z-index: 3;
}

nav a, nav .navLogo {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.navLogo{
  width:45px;
}

nav a.current {
  border-top: none;
  border-bottom: 2px solid black;
}

