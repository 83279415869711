nav {
  top: 0;
  left: 2vw; /* same than main content*/
  width:96vw; /* 100 - 2x margin for content */
  height: 6em;
  position:fixed;
  display: flex;
  align-items: center;
  z-index: 3;
}

nav a, nav .navLogo {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.navLogo{
  width:45px;
}

nav a.current {
  border-top: none;
  border-bottom: 2px solid black;
}
