.burgericon
{
    cursor: pointer;
}

.bar1,
.bar2,
.bar3
{
    width: 25px;
    height: 3px;
    background-color: #333;
    margin-bottom: 5px;
    transition: 0.4s;
}

.bar3
{
    margin-bottom: 0;
}

.menudisplayed .bar1
{
    -webkit-transform: translate(0px, 8.5px) rotate(-45deg) ;
    transform: translate(0px, 8.5px) rotate(-45deg) ;
    background-color:white;
}

.menudisplayed .bar2
{
    opacity: 0;
}

.menudisplayed .bar3
{
    -webkit-transform: translate(0px, -7.5px) rotate(45deg) ;
    transform: translate(0px, -7.5px) rotate(45deg) ;
    background-color:white;
}
